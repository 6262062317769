import React from 'react';
import {useSelector} from 'react-redux';
import {makeSelectHotel, makeSelectUser} from '../Authentication/selectors';

function BranchDetails() {
  const {branch} = useSelector(makeSelectHotel());
  const {user_address} = useSelector(makeSelectUser());
  return (
    <>
      <div className="branch-details">
        <h2 className="title">{branch.official_title}</h2>
        <h3 className="mobile">
          Contact #: <strong>{branch.mobile}</strong>
        </h3>
        <h3 className="email">
          Email: <strong>{branch.email}</strong>
        </h3>
        <h3 className="address">
          Address: <strong>{branch.address}</strong>
        </h3>
      </div>

      {user_address && (
        <h3 className="user-name">
          POS User: <strong>{user_address.name}</strong>
        </h3>
      )}
    </>
  );
}

export default BranchDetails;
